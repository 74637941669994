import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // For success feedback
import { useFetchData, useMutateData } from "../../../api/axios";

const InputField = ({
  value: initialValue,
  prefix,
  suffix,
  name,
  orderId,
  urlPrefix,
  queryClient,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showSuccess, setShowSuccess] = useState(false); // For success feedback

  // Fetch existing field data
  const { data, error, isLoading } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  // Update value when data is fetched
  useEffect(() => {
    if (data?.data) {
      setValue(data.data);
    }
  }, [data]);

  // Mutation to save the edited value
  const { mutate, error: mutationError } = useMutateData({
    method: "PATCH",
    url: `${urlPrefix}`,
  });

  const handleSave = (e) => {
    e.stopPropagation(); // Prevent TextField onClick from triggering
    const payload = {
      field: name,
      value: value,
      orderId: orderId,
    };
    console.log("Form Data:", payload);

    mutate(payload, {
      onSuccess: (data) => {
        console.log("Save Success:", data);
        queryClient.invalidateQueries([name]); // Refetch the query
        setIsEditable(false); // Make input non-editable
        setShowSuccess(true); // Show success feedback
        setTimeout(() => setShowSuccess(false), 1000); // Hide feedback after 1 second
      },
      onError: (error) => {
        console.error("Save Error:", error);
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isEditable) {
      handleSave(e);
    }
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      sx={{
        cursor: "pointer",
        "& .MuiInputBase-input": {
          color: "#384B70", // Text color
        },
      }}
      value={isEditable ? value : data?.data || ""}
      onChange={(e) => setValue(e.target.value)}
      onClick={() => setIsEditable(true)} // Enable editing on click
      onKeyDown={handleKeyDown} // Save on Enter key press
      InputProps={{
        readOnly: !isEditable, // Enable or disable editing
        style: {
          backgroundColor: isEditable ? "#f0f0f0" : "white", // Grey background when editable
          cursor: isEditable ? "text" : "pointer", // Appropriate cursor
        },
        startAdornment: (
          <>
            {prefix && (
              <InputAdornment position="start">{prefix}</InputAdornment>
            )}
            {isEditable && (
              <IconButton onClick={handleSave}>
                <SaveIcon color="primary" />
              </IconButton>
            )}
            {!isEditable && showSuccess && (
              <IconButton disabled>
                <CheckCircleIcon color="success" /> {/* Success feedback */}
              </IconButton>
            )}
          </>
        ),
        endAdornment: (
          <>
            {suffix && <InputAdornment position="end">{suffix}</InputAdornment>}
          </>
        ),
      }}
      error={Boolean(error || mutationError)} // Show error state
      helperText={
        error ? "Error loading data" : mutationError ? "Save failed" : ""
      }
    />
  );
};

export default InputField;
