import { Button, Typography, TextField, Divider } from "@mui/material";
import { useFetchData, useMutateData } from "../../api/axios";
import useHelperFunctions from "../../helpers/functions";
import { QueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import SectionHeader from "../shared/typography/SectionHeader";

function Settings() {
  const { currentOrder } = useHelperFunctions();
  const queryClient = new QueryClient();

  const { data, error, isLoading, isFetching } = useFetchData({
    queryKey: ["support-emails"],
    url: `/support/get-emails`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
      onError: (err) => {
        if (err?.response?.status === 404) {
          console.error("Support emails not found");
        } else {
          console.error("Error fetching support emails:", err);
        }
      },
    },
  });

  const { mutate: patchEmail, error: errorUpdatingEmail } = useMutateData({
    method: "PATCH",
    url: "/support/change-email",
  });

  const handleUpdate = (field, value, setFeedback) => {
    const content = {
      field,
      value,
      orderId: currentOrder?.orderId || null,
    };

    patchEmail(content, {
      onSuccess: () => {
        queryClient.invalidateQueries(["support-emails"]);
        setFeedback("Updated successfully!");
        setTimeout(() => setFeedback(""), 3000); // Clear feedback after 3 seconds
        console.log(`${field} updated successfully`);
      },
      onError: (error) => {
        console.error(`Error updating ${field}:`, error);
        setFeedback("Update failed!");
        setTimeout(() => setFeedback(""), 3000); // Clear feedback after 3 seconds
      },
    });
  };

  return (
    <div>
      <SectionHeader>Contact Emails</SectionHeader>
      <Divider />
      <DetailsContainer
        buttonText="Update"
        data={data?.salesEmail || "No email provided"}
        dataHeader="Sales Email"
        handleOnClick={(value, setFeedback) =>
          handleUpdate("salesEmail", value, setFeedback)
        }
      />
      <DetailsContainer
        buttonText="Update"
        data={data?.financeEmail || "No email provided"}
        dataHeader="Finance Email"
        handleOnClick={(value, setFeedback) =>
          handleUpdate("financeEmail", value, setFeedback)
        }
      />
      <DetailsContainer
        buttonText="Update"
        data={data?.fallbackEmail || "No email provided"}
        dataHeader="Fallback Email"
        handleOnClick={(value, setFeedback) =>
          handleUpdate("fallbackEmail", value, setFeedback)
        }
      />
      <Divider />
    </div>
  );
}

export default Settings;

function DetailsContainer({ dataHeader, data, handleOnClick, buttonText }) {
  const [inputValue, setInputValue] = useState(data);
  const [feedback, setFeedback] = useState("");

  // Sync state when data changes
  useEffect(() => {
    setInputValue(data);
  }, [data]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    handleOnClick(inputValue, setFeedback);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "150px 250px 150px",
        columnGap: "24px",
        margin: "24px 0",
      }}
    >
      <Typography variant="h6">{dataHeader}</Typography>
      <div style={{ position: "relative", flex: 1 }}>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          variant="standard"
          size="small"
          style={{ width: "100%" }}
        />
        {feedback && (
          <Typography
            variant="caption"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              color: "green",
              marginTop: "4px",
            }}
          >
            {feedback}
          </Typography>
        )}
      </div>
      <Button variant="outlined" onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </div>
  );
}
