import ImageUpload from "../shared/form/upload/Upload";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useFetchData } from "../../api/axios";
import NoteUpload from "./AddNote";
import { useState } from "react";
import { Box, Button } from "@mui/material";

function NotesImages({ currentReport, currentReportId, currentOrder }) {
  const [isNoteBoxVisible, setIsNoteBoxVisible] = useState(false);

  const toggleNoteBox = () => {
    setIsNoteBoxVisible((prev) => !prev);
  };

  return (
    <div className="quickActions">
      <AccordionOption title={"Images"}>
        <ImageUpload
          url={`report/${currentReport}/${currentReportId}/upload-image`}
          orderId={currentOrder["orderId"]}
          invalidationQuery={"order-images"}
        />
        <ImageCarousal
          currentReport={currentReport}
          currentReportId={currentReportId}
          orderId={currentOrder["orderId"]}
        />
      </AccordionOption>

      <AccordionOption title={"Notes"}>
        <GetNotes
          currentReport={currentReport}
          currentReportId={currentReportId}
          orderId={currentOrder["orderId"]}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          <Button
            variant="outlined"
            onClick={toggleNoteBox}
            sx={{ alignSelf: "flex-start" }}
          >
            {isNoteBoxVisible ? "Close notepad" : "Add New Note"}
          </Button>
          {isNoteBoxVisible && (
            <NoteUpload
              url={`report/${currentReport}/${currentReportId}/add-note`}
              orderId={currentOrder["orderId"]}
              invalidationQuery={"order-notes"}
              setIsNoteBoxVisible={setIsNoteBoxVisible}
            />
          )}
        </Box>
        {/* <NoteUpload
          url={`report/${currentReport}/${currentReportId}/add-note`}
          orderId={currentOrder["orderId"]}
          invalidationQuery={"order-notes"}
        /> */}
      </AccordionOption>
    </div>
  );
}

export default NotesImages;

function AccordionOption({ children, title }) {
  return (
    <div>
      <Accordion
        sx={{
          boxShadow: "none",
          mb: "8px",
          border: "1px dashed lightgrey",
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography color="textSecondary">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

function ImageCarousal({ currentReport, currentReportId, orderId }) {
  const { data, error } = useFetchData({
    queryKey: ["order-images"],
    url: `report/${orderId}/${currentReport}/${currentReportId}`,
  });

  // console.log(data.data);
  return (
    <div className="carousal">
      {data?.data && data?.data.length > 0
        ? [...data.data].reverse().map((u, index) => (
            <div className="imageContainer" key={index}>
              <img src={u} alt={` ${index}`} className="carousalImage" />
            </div>
          ))
        : ""}
    </div>
  );
}

function GetNotes({ currentReport, currentReportId, orderId }) {
  const { data, error } = useFetchData({
    queryKey: ["order-notes"],
    url: `report/${orderId}/${currentReport}/${currentReportId}/get-notes`,
  });

  console.log(data?.data);
  return (
    <div className="notesContainer">
      {data?.data && data?.data.length > 0
        ? [...data.data].reverse().map((u, index) => (
            <div className="note" key={index}>
              <Typography variant="body" color="textPrimary">
                {u}
              </Typography>
            </div>
          ))
        : ""}
    </div>
  );
}
