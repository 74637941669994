import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
  TablePagination,
  Tooltip,
  Button,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import useHelperFunctions from "../../../helpers/functions";
import { useFetchData } from "../../../api/axios";

// Helper functions
const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return { color: "gray", text: "Approval Pending" };
    case 2:
      return { color: "blue", text: "In Process" };
    case 3:
      return { color: "orange", text: "Completed" };
    case 4:
      return { color: "green", text: "Dispatched" };
    case 5:
      return { color: "red", text: "On Hold" };
    default:
      return { color: "black", text: "Unknown Status" };
  }
};

const getStageDefinition = (status) => {
  switch (status) {
    case 0:
      return "Incoming";
    case 1:
      return "Check Sheet";
    case 2:
      return "Damage Report";
    case 3:
      return "In Process Inspection";
    case 4:
      return "Old Bearing";
    case 5:
      return "New Bearing";
    case 6:
      return "Electrical Test";
    case 7:
      return "Final Inspection";
    case 8:
      return "Testing and Balancing";
    default:
      return "Order Created";
  }
};

function OrdersTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("srNumber");
  const [sortDirection, setSortDirection] = useState("asc");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const { clearOrderIndex, setOrderIndex } = useHelperFunctions();

  const { data, error, isLoading, isFetching } = useFetchData({
    queryKey: ["recentOrders"],
    url: "/order/recent",
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  useEffect(() => {
    setOrders(data?.data || []);
  }, [data]);

  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const filteredData = orders
    ?.filter(
      (row) =>
        row.clientName?.toLowerCase().includes(searchTerm) ||
        row.inwardNumber.toLowerCase().includes(searchTerm) ||
        row.customerName?.toLowerCase().includes(searchTerm) // Include customerName in the search
    )
    .sort((a, b) => {
      const order = sortDirection === "asc" ? 1 : -1;
      const aValue = a[sortColumn]?.toString().toLowerCase() || "";
      const bValue = b[sortColumn]?.toString().toLowerCase() || "";
      return aValue > bValue ? order : aValue < bValue ? -order : 0;
    });

  const paginatedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const { navigate, hideOutlet } = useHelperFunctions();

  function handleOpenOrder(row) {
    clearOrderIndex();
    setOrderIndex(row);
    navigate(`/${row.orderId}`);
    hideOutlet();
  }

  return (
    <Paper elevation={0} p={0} m={0} sx={{ maxWidth: "932px" }}>
      <TextField
        label="Search by Client Name, Inward Number, or Customer Name"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                "srNumber",
                "customerName", // Added customerName column
                "inwardNumber",
                "registrationDate",
                // "stage",
                // "status",
                "action",
              ].map((column) => (
                <TableCell key={column}>
                  <TableSortLabel
                    active={sortColumn === column}
                    direction={sortColumn === column ? sortDirection : "asc"}
                    onClick={() => handleSort(column)}
                  >
                    {column
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((row, index) => (
              <Tooltip title="Click to open" arrow key={row.orderId}>
                <TableRow
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleOpenOrder(row)}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.customerName || "N/A"}</TableCell>{" "}
                  {/* Added customerName */}
                  <TableCell>{row.inwardNumber}</TableCell>
                  <TableCell>
                    {new Date(row.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </TableCell>
                  {/* <TableCell>{getStageDefinition(row.stage)}</TableCell> */}
                  {/* <TableCell
                    style={{ color: getStatusColor(row.status).color }}
                  >
                    {getStatusColor(row.status).text}
                  </TableCell> */}
                  <TableCell>
                    <Button size="small">Open</Button>
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredData?.length || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
      />
    </Paper>
  );
}

export default OrdersTable;
