import useHelperFunctions from "../../helpers/functions";
import SimpleCard from "../shared/cards/SimpleCard";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import FinalInspectionReportDownload from "./FinalInspectionReportDownload";
import { axiosInstance, useFetchData } from "../../api/axios";

export default function AvailableReport() {
  const { navigate, currentOrder } = useHelperFunctions();
  function onActionOneClick(link) {
    navigate(link);
  }

  async function handleSendEmail() {
    try {
      // Render the PDF to a blob
      // const blob = await pdf(<FinalInspectionReportDownload />).toBlob();
      const pdfBlob = new Blob(
        [<FinalInspectionReportDownload />],
        { type: "application/pdf" },
        `My PDF_${1}.pdf`
      );

      // Log the blob for debugging
      console.log("Blob details:", {
        type: pdfBlob.type,
        size: pdfBlob.size,
        pdfBlob,
      });

      // Create FormData to send the file
      const formData = new FormData();
      formData.append("file", pdfBlob, "FinalInspectionReport.pdf");

      // Log the FormData contents (optional, for debugging purposes)
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      // Uncomment this part when you are ready to test the email functionality
      const response = await axiosInstance.post(
        "/report/email-report",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("Email sent successfully!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the email.");
    }
  }

  return (
    <div>
      <SimpleCard
        cardTitle={"Final Inspection Report"}
        cardContent={
          "Click on this card to download the final inspection report"
        }
        cardActionOne={"download"}
        onActionOneClick={() =>
          onActionOneClick("/final-inspection-report-download")
        }
        // cardActionTwo={"email"}
        // onActionTwoClick={() => handleSendEmail()}
      />
    </div>
  );
}
