import { useEffect, useState } from "react";
import "./orderStages.css";
import SectionHeader from "../shared/typography/SectionHeader";
import useHelperFunctions from "../../helpers/functions";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchData } from "../../api/axios";
import { Button, Divider } from "@mui/material";
import { forms } from "../../config/order/order";
import AvailableReport from "../downloadReports/AvailableReports";
import SectionSubHeader from "../shared/typography/SectionSubHeader";
import NotesImages from "../report/NotesImages";
import ImageGallery from "../report/ImageGallery";

function OrderStages() {
  const [different, setDifferent] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const {
    navigate: customNavigate,
    currentOrder,
    clearOrderIndex,
    setOrderIndex,
    userRole,
  } = useHelperFunctions();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { data: approvedBySales, isLoading: loadingSalesApproval } =
    useFetchData({
      queryKey: ["salesApproval"],
      url: `/report/${"IncomingAlert"}/${
        currentOrder.IncomingAlert
      }/field?field=${encodeURIComponent(
        "salesApproval"
      )}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
      options: {
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: "always",
        staleTime: 30 * 60 * 1000,
      },
    });

  const { data: approvedByFinance, isLoading: loadingFinanceApproval } =
    useFetchData({
      queryKey: ["financeApproval"],
      url: `/report/${"IncomingAlert"}/${
        currentOrder.IncomingAlert
      }/field?field=${encodeURIComponent(
        "financeApproval"
      )}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
      options: {
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: "always",
        staleTime: 30 * 60 * 1000,
      },
    });

  console.log(approvedBySales?.data, "approved by sales");
  console.log(approvedByFinance?.data, "approved by finance");
  const {
    data: newOrderData,
    isLoading,
    error,
  } = useFetchData({
    queryKey: ["order", orderId],
    url: `/order/${orderId}`, // Update this URL to match your API
    options: {
      enabled:
        !currentOrder ||
        !currentOrder.orderId ||
        currentOrder.orderId !== orderId,
    },
  });

  useEffect(() => {
    if (!currentOrder) {
      setLoadingMessage("Loading current order..."); // Show loading message if no current order
    }
  }, [currentOrder]);

  useEffect(() => {
    if (newOrderData && (!currentOrder || currentOrder.orderId !== orderId)) {
      // Clear current order and set new order data
      clearOrderIndex();
      setOrderIndex(newOrderData["data"]); // Assuming setOrderIndex updates currentOrder
      setDifferent(false); // Reset different to show timeline
      setLoadingMessage(""); // Clear loading message once data is loaded
    }
  }, [newOrderData, orderId, currentOrder, clearOrderIndex, setOrderIndex]);

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isLoading, error, different]);

  // Handle loading a new report
  function handleLoadReport(data) {
    customNavigate(data.url(currentOrder));
  }

  // Redirect to home if order not found
  const handleRedirectToHome = () => {
    navigate("/");
  };

  if (loadingMessage) {
    return <p>{loadingMessage}</p>;
  }

  if (isLoading || different) {
    return <p>Loading order...</p>;
  }

  // Conditional rendering based on loading, error, or different state
  if (isLoading || different) {
    return <p>Loading order...</p>;
  }

  if (error) {
    return (
      <div
        style={{
          margin: "64px",
          display: "flex",
          gap: "24px",
          flexDirection: "column",
          width: "360px",
        }}
      >
        <h2>Order Not Found</h2>
        <p>The requested order could not be found. Please try again.</p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirectToHome}
        >
          Go to Home
        </Button>
      </div>
    );
  }

  return (
    <div>
      {userRole !== "user" && (
        <div className="reportEditSection">
          <SectionHeader>Incoming</SectionHeader>
          <StationForms>
            {forms["incomingAlert"].map((f) => (
              <Form form={f} key={f.label} navigate={handleLoadReport} />
            ))}
          </StationForms>
          {/* order.isApproved && */}
          <SectionSubHeader s={{ color: "#B8001F" }}>
            {!approvedBySales?.data && "Sales Approval Pending"}
          </SectionSubHeader>
          <SectionSubHeader s={{ color: "#B8001F" }}>
            {!approvedByFinance?.data && "Finance Approval Pending"}
          </SectionSubHeader>
          {approvedBySales?.data && approvedByFinance?.data && (
            <div className="editAfterApproved">
              <SectionHeader>Dismantling</SectionHeader>
              <StationForms>
                {forms["dismantling"].map((f) => (
                  <Form form={f} key={f.label} navigate={handleLoadReport} />
                ))}
              </StationForms>
              <SectionHeader>In Process inspection</SectionHeader>
              <StationForms>
                {forms["inProcessInspection"].map((f) => (
                  <Form form={f} key={f.label} navigate={handleLoadReport} />
                ))}
              </StationForms>
              <SectionHeader>Final inspection</SectionHeader>
              <StationForms>
                {forms["finalInspection"].map((f) => (
                  <Form form={f} key={f.label} navigate={handleLoadReport} />
                ))}
              </StationForms>
            </div>
          )}
          <Divider />
        </div>
      )}
      {approvedBySales?.data && approvedByFinance?.data && (
        <div className="reportDownloadSection" style={{ marginTop: "24px" }}>
          <SectionHeader>Download Reports</SectionHeader>
          <AvailableReport />
          {userRole === "user" && <ImageGallery />}
          {/* <ImageGallery /> */}
        </div>
      )}
    </div>
  );
}

export default OrderStages;

function StationForms({ children }) {
  return <div className="deck">{children}</div>;
}

function Form({ form, navigate }) {
  return (
    <div className="stationForm" onClick={() => navigate(form)}>
      {form.label}
    </div>
  );
}
