import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import setco from "../../assets/staticImages/setco.png";
import spintrack from "../../assets/staticImages/spintrack.png";
import { AllReports } from "../../config/pdfRports/AllReports";
import UseGetMandatoryData from "./Data";
import { useFetchData } from "../../api/axios";
import useHelperFunctions from "../../helpers/functions";
import { useEffect, useState } from "react";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 10,
  },
  title: {
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#FF0000", // Red background for title
    color: "#FFFFFF",
    padding: 5,
    marginBottom: 10,
    marginTop: 10,
  },

  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRow: {
    flexDirection: "row",
  },

  tableHeader: {
    backgroundColor: "#87CEEB", // Light blue background for headers
    fontWeight: "bold",
  },

  tableColBasic: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol8: {
    width: "12.5%", // Adjust column width for 8 columns
  },

  tableCol_30: {
    width: "30%",
  },
  tableCol_10: {
    width: "10%",
  },
  tableCol_5: {
    width: "5%",
  },
  tableCol_15: {
    width: "15%",
  },

  tableCol: {
    width: "12.5%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol7: {
    width: "14.28%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol3: {
    width: "33.33%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },

  tableCell: {
    fontSize: 8,
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  spacer: {
    marginTop: 30,
  },
});

function FinalInspectionReportDownload() {
  const { spindleModel, taperSpecifications, customerName, inwardNumber } =
    UseGetMandatoryData();

  const { currentOrder } = useHelperFunctions();

  const { data: report } = useFetchData({
    queryKey: ["Final-Inspection-Report-Download"],
    url: `/report/getReport/${currentOrder.orderId}/FinalInspectionReport/${currentOrder.FinalInspectionReport}`,
  });

  const { data: runs } = useFetchData({
    queryKey: ["Testing-Balancing-Report-Download"],
    url: `/report/getReport/${currentOrder.orderId}/TestingAndBalancingReport/${currentOrder.TestingAndBalancingReport}`,
  });

  const tableMeta = [
    "Spindle Model",
    spindleModel,
    "Sr. No",
    "-",
    "Job No.",
    inwardNumber,
    "Taper Type",
    taperSpecifications,
  ];

  let data = [];
  if (taperSpecifications) {
    data = AllReports[taperSpecifications.toLowerCase()];
  }

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <SetcoFormHeader />
          <CustomerAndDate customerName={customerName} />
          <MetaStrip tableMeta={tableMeta} />

          <Text style={styles.title}>FINAL INSPECTION REPORT</Text>
          <TableContainer>
            <FinalInspectionHeaders />
            <TableRows data={data} runs={runs} report={report} />
          </TableContainer>

          {/* testing and balancing */}
          <Text style={styles.title}>TESTING AND BALANCING REPORT</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Sr.No</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Trial Run</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>RPM</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Vibration (Front)</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Vibration (Rear)</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Time</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Remark</Text>
              </View>
            </View>

            {/* Table Body */}
            {[
              {
                srNo: 1,
                trialRun: "Trial Run-1",
                rpm: runs?.data["tr1_rpm"],
                tempFront: runs?.data["tr1_vibrationFront"],
                tempRear: runs?.data["tr1_vibrationRear"],
                time: runs?.data["tr1_time"],
                remark: "",
              },
              {
                srNo: 2,
                trialRun: "Trial Run-2",
                rpm: runs?.data["tr2_rpm"],
                tempFront: runs?.data["tr2_vibrationFront"],
                tempRear: runs?.data["tr2_vibrationRear"],
                time: runs?.data["tr2_time"],
                remark: "",
              },
              {
                srNo: 3,
                trialRun: "Trial Run-3",
                rpm: runs?.data["tr3_rpm"],
                tempFront: runs?.data["tr3_vibrationFront"],
                tempRear: runs?.data["tr3_vibrationRear"],
                time: runs?.data["tr3_time"],
                remark: "",
              },
              {
                srNo: 4,
                trialRun: "Trial Run-4",
                rpm: runs?.data["tr4_rpm"],
                tempFront: runs?.data["tr4_vibrationFront"],
                tempRear: runs?.data["tr4_vibrationRear"],
                time: runs?.data["tr4_time"],
                remark: "",
              },
              {
                srNo: 5,
                trialRun: "Trial Run-5",
                rpm: runs?.data["tr5_rpm"],
                tempFront: runs?.data["tr5_vibrationFront"],
                tempRear: runs?.data["tr5_vibrationRear"],
                time: runs?.data["tr5_time"],
                remark: "",
              },
            ].map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.srNo}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.trialRun}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.rpm}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.tempFront}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.tempRear}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.time}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.remark}</Text>
                </View>
              </View>
            ))}
          </View>
          {/* Remark Section */}
          <View>
            <View
              style={{
                flex: 1,
                padding: 5,
                marginTop: "24px",
                marginBottom: "24px",
              }}
            >
              <Text style={[{ textAlign: "left", fontSize: "12px" }]}>
                Remark - Please warm up spindle at your end (at least 60min)
                before handover to production
              </Text>
            </View>
          </View>
          <Text style={styles.title}>Approved By</Text>
          <View style={[styles.spacer]} />
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Testing In-charge</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Assembly In-charge</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>ISR Manager</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default FinalInspectionReportDownload;

/**
 *
 * form header section
 */

function SetcoLogo() {
  return (
    <View>
      <Image src={setco} style={{ height: "auto", width: "48px" }} />
    </View>
  );
}

function SpintrackLogo() {
  return (
    <View>
      <Image
        src={spintrack}
        style={{
          height: "auto",
          width: "48px",
          marginTop: 5,
          marginBottom: 5,
        }}
      />
    </View>
  );
}

function SetcoCompanyDetails() {
  return (
    <View style={{ display: "flex", alignItems: "center" }}>
      <Text style={{ fontSize: "14px" }}>Setco Spindles India Pvt Ltd</Text>
      <Text style={{ fontSize: "12px" }}>Final Inspection report</Text>
    </View>
  );
}

function SetcoFormHeader() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 15,
        marginTop: 15,
      }}
    >
      <SetcoLogo />

      <SetcoCompanyDetails />
      <SpintrackLogo />
    </View>
  );
}

/**
 *
 * customer and date
 */

function CustomerInfo({ customerName }) {
  return (
    <View>
      <Text>Customer Name: {customerName}</Text>
    </View>
  );
}

function CurrentDate() {
  return (
    <View>
      <Text>
        Date:{" "}
        {new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </Text>
    </View>
  );
}

function CustomerAndDate({ customerName }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: "10px",
      }}
    >
      <CustomerInfo customerName={customerName} />
      <CurrentDate />
    </View>
  );
}

/**
 *
 * report meta
 */

function MetaStripContainer({ children }) {
  return (
    <View style={{ margin: "10px 0" }}>
      <View
        style={[
          styles.tableRow,
          styles.tableHeader,
          { borderLeftWidth: 1, borderTopWidth: 1 },
        ]}
      >
        {children}
      </View>
    </View>
  );
}

function MetaStrip({ tableMeta }) {
  console.log(tableMeta);
  return (
    <MetaStripContainer>
      {tableMeta.map((meta) => {
        return (
          <View style={[styles.tableColBasic, styles.tableCol8]}>
            <Text style={[styles.tableCell]}>{meta}</Text>
          </View>
        );
      })}
    </MetaStripContainer>
  );
}

/**
 *
 * final Inspection report header
 */

function TableContainer({ children }) {
  return <View style={styles.table}>{children}</View>;
}

const tableHeaders = [
  { title: "Sr.", styleName: "tableCol_5" },
  { title: "Test Name", styleName: "tableCol_30" },
  { title: "Measured", styleName: "tableCol_10" },
  { title: "Notes", styleName: "tableCol_15" },
  { title: "Lower Limit", styleName: "tableCol_10" },
  { title: "Upper Limit", styleName: "tableCol_10" },
  { title: "Units", styleName: "tableCol_5" },
  { title: "Remarks", styleName: "tableCol_15" },
];

function FinalInspectionHeaderContainer({ children }) {
  return <View style={[styles.tableRow, styles.tableHeader]}>{children}</View>;
}

function FinalInspectionHeaders() {
  return (
    <FinalInspectionHeaderContainer>
      {tableHeaders.map((header) => {
        return (
          <View style={[styles[header.styleName], styles.tableColBasic]}>
            <Text style={styles.tableCell}>{header.title}</Text>
          </View>
        );
      })}
    </FinalInspectionHeaderContainer>
  );
}

function TableData({ children }) {
  return <>{children}</>;
}

function TableRows({ data, runs, report }) {
  console.log(data);
  return (
    <TableData>
      {data?.map((row, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCol_5, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{index + 1}</Text>
          </View>
          <View style={[styles.tableCol_30, styles.tableColBasic]}>
            <Text style={[styles.tableCell]}>{row.testName}</Text>
          </View>
          <View style={[styles.tableCol_10, styles.tableColBasic]}>
            <Text style={styles.tableCell}>
              {row.testName === "Residual Imbalance - Front"
                ? runs?.data["residualImbalanceFront"]
                : row.testName === "Residual Imbalance - Rear"
                ? runs?.data["residualImbalanceRear"]
                : report?.data[row.fieldName]}
            </Text>
          </View>
          <View style={[styles.tableCol_15, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{row.notes}</Text>
          </View>
          <View style={[styles.tableCol_10, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{row.lowerLimit}</Text>
          </View>
          <View style={[styles.tableCol_10, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{row.upperLimit}</Text>
          </View>
          <View style={[styles.tableCol_5, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{row.units}</Text>
          </View>
          <View style={[styles.tableCol_15, styles.tableColBasic]}>
            <Text style={styles.tableCell}>{row.remark}</Text>
          </View>
        </View>
      ))}
    </TableData>
  );
}
