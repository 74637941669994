import { Outlet } from "react-router-dom";
import "./main.css";
import useUIStore from "../../../stores/useUiStore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function MainArea({ children, isTabletOrBelow }) {
  // const [showOutlet, setShowOutlet] = useState(true);
  const showOutlet = useUIStore((state) => state.isOutletOpen);
  const hideOutlet = useUIStore((state) => state.hideOutlet);

  return (
    <div className="mainArea ">
      {showOutlet && (
        <div className="outletContainer">
          <div className="highlightOffIconContainer" onClick={hideOutlet}>
            <HighlightOffIcon sx={{ opacity: "50%" }} />
          </div>
          <Outlet />
        </div>
      )}
      <div
        className="childContainer"
        style={{
          minWidth: isTabletOrBelow ? "100%" : showOutlet ? "" : "50%",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default MainArea;
