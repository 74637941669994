import useHelperFunctions from "../../helpers/functions";
import "./report.css";

import { Button, Typography } from "@mui/material";
import GroupedReportFields from "../shared/renders/GroupedReportFields";
import { useFetchData } from "../../api/axios";
import { lookup } from "../../config/lookup/lookup";
import { useLocation } from "react-router-dom";
import NotesImages from "./NotesImages";
import TestingAndBalancing from "../seperateForms/TestingAndBalancing/TestingAndBalancing";
import FinalInspection from "../seperateForms/FinalInspection/FinalInspection";

function ReportPage() {
  const { navigate, showOutlet, currentOrder } = useHelperFunctions();

  let location = useLocation();

  let currentReport = location.pathname.split("/")[1];
  let currentReportId = location.pathname.split("/")[2];

  function handleOpenReport() {
    console.log(currentOrder);
    navigate(`/${currentOrder["orderId"]}`);
  }

  function handleOpenField(data, state) {
    navigate(data, { state });
    showOutlet();
  }

  const { data: inwardNumber } = useFetchData({
    queryKey: ["inwardNumberOnHeader"],
    url: `/order/${currentOrder.orderId}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  return (
    <div className="reportPageContainer">
      <div className="reportHeader">
        <div className="reportNav">
          <div className="reportNavActions">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenReport()}
            >
              Switch Report
            </Button>
          </div>

          <div className="reportNavInfo">
            <Typography variant="h5" color="primary" mark={false}>
              {lookup(currentReport)}
            </Typography>

            <Typography
              variant="h6"
              color="primary"
              sx={{ fontStyle: "oblique" }}
            >
              {inwardNumber ? currentOrder["inwardNumber"] : ""}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontStyle: "oblique" }}
            >
              {currentOrder ? currentOrder["customerName"] : ""}
            </Typography>
            <div style={{ height: "100%" }}></div>
          </div>
        </div>
      </div>
      <div className="reportPage">
        <NotesImages
          currentOrder={currentOrder}
          currentReport={currentReport}
          currentReportId={currentReportId}
        />
        {currentReport === "TestingAndBalancingReport" && (
          <TestingAndBalancing />
        )}
        {currentReport === "FinalInspectionReport" && <FinalInspection />}
        {currentReport !== "TestingAndBalancingReport" &&
        currentReport !== "FinalInspectionReport" ? (
          <GroupedReportFields handleClick={handleOpenField} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ReportPage;
