import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "../../api/axios";

function NoteUpload({ url, orderId, invalidationQuery, setIsNoteBoxVisible }) {
  const [note, setNote] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);

  const queryClient = useQueryClient();

  // Handle text note submission
  const uploadNote = async () => {
    if (!note.trim()) return;

    try {
      setUploading(true);
      const response = await axiosInstance.patch(url, { note, orderId });
      setUploading(false);
      queryClient.invalidateQueries([invalidationQuery]);
      setNote(""); // Clear the note input
      setUploadSuccess(response.data.message || "Note uploaded successfully!");
      setIsNoteBoxVisible(false);
    } catch (error) {
      setUploading(false);
      console.error("Error uploading note:", error);
      setUploadSuccess(
        "We are presently working on note upload functionality."
      );
    }
  };

  // Clear the note
  const handleClearNote = () => {
    setNote("");
    setUploadSuccess(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h6">Add a Note</Typography>
      <TextField
        label="Write your note"
        multiline
        rows={4}
        variant="outlined"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        fullWidth
      />
      <Box mt={2} display="flex" gap={3}>
        <Button
          variant="text"
          color="secondary"
          onClick={handleClearNote}
          sx={{ mt: 1 }}
        >
          Clear Note
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={uploadNote}
          disabled={uploading || !note.trim()}
        >
          {uploading ? <CircularProgress size={24} /> : "Upload Note"}
        </Button>
      </Box>
      {uploadSuccess && (
        <Typography
          mt={2}
          color={uploadSuccess.includes("successfully") ? "green" : "brown"}
        >
          {uploadSuccess}
        </Typography>
      )}
    </Box>
  );
}

export default NoteUpload;
