import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import useHelperFunctions from "../../../helpers/functions";
import { FinalInspectionReport } from "../../../config/reports/FinalInspectionReport/FinalInspectionReport";
import InputField from "../components/InputField";
import {
  TableCell,
  TableCellHeader1,
  TableGroupHeader,
  TwoColumnTable,
} from "../components/TableComponents";

function FinalInspection() {
  const [value, setValue] = useState(0);
  const queryClient = useQueryClient(); // can you take this to helpers?

  const { location, currentOrder } = useHelperFunctions();

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const firstSegment = pathSegments[0];
  const secondSegment = pathSegments[1];
  const urlPrefix = `/report/${firstSegment}/${secondSegment}`;
  return (
    <div>
      {FinalInspectionReport.map((group) => {
        return (
          <div>
            <TableGroupHeader>{group.groupHeader}</TableGroupHeader>
            <TwoColumnTable>
              {group.components.map((c) => {
                return (
                  <TableCell width1={"300px"}>
                    <TableCellHeader1>{c.label}</TableCellHeader1>
                    <InputField
                      prefix={""}
                      suffix={c.unit}
                      value={value}
                      urlPrefix={urlPrefix}
                      orderId={currentOrder.orderId}
                      key={c.name}
                      name={c.name}
                      queryClient={queryClient}
                    />
                  </TableCell>
                );
              })}
            </TwoColumnTable>
          </div>
        );
      })}
    </div>
  );
}

export default FinalInspection;
