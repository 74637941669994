export const bearing = {
  name: "bearing",
  label: "Bearing",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const shaftTaper = {
  name: "shaftTaper",
  label: "Shaft Taper",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const shaftOd = {
  name: "shaftOd",
  label: "Shaft OD",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const mechanicalSeal = {
  name: "mechanicalSeal",
  label: "Mechanical Seal",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
    { value: "notApplicable", label: "Not Applicable" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const pulley = {
  name: "pulley",
  label: "Pulley",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const encoder = {
  name: "encoder",
  label: "Encoder",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
    { value: "notApplicable", label: "notApplicable" },
    { value: "notReceived", label: "notReceived" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: [
      "ok",
      "damaged",
      "rework",
      "replace",
      "notApplicable",
      "notReceived",
    ],
  },
};

export const rotaryUnion = {
  name: "rotaryUnion",
  label: "Rotary Union",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
    { value: "notApplicable", label: "notApplicable" },
    { value: "notReceived", label: "notReceived" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: [
      "ok",
      "damaged",
      "rework",
      "replace",
      "notApplicable",
      "notReceived",
    ],
  },
};

export const spacerDistanceSleeve = {
  name: "spacerDistanceSleeve",
  label: "Spacer Sleeve and Distance Sleeve",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const tenon = {
  name: "tenon",
  label: "Tenon",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
    { value: "notApplicable", label: "notApplicable" },
    { value: "notReceived", label: "notReceived" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: [
      "ok",
      "damaged",
      "rework",
      "replace",
      "notApplicable",
      "notReceived",
    ],
  },
};

export const oRings = {
  name: "oRings",
  label: "O-Rings",
  type: "dropdown",
  options: [
    { value: "ok", label: "OK" },
    { value: "damaged", label: "Damaged" },
    { value: "rework", label: "Rework" },
    { value: "replace", label: "Replace" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["ok", "damaged", "rework", "replace"],
  },
};

export const drawbarCollets = {
  name: "drawbarCollets",
  label: "Drawbar/Collets",
  type: "dropdown",
  options: [
    { value: "fingers", label: "Fingers" },
    { value: "balls", label: "Balls" },
    { value: "notReceived", label: "Not Received" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["collets", "fingers", "notReceived"],
  },
};

export const drawbarSprings = {
  name: "drawbarSprings",
  label: "Springs",
  type: "dropdown",
  options: [
    { value: "disc", label: "Disc" },
    { value: "helical", label: "Helical" },
    { value: "notReceived", label: "Not Received" },
  ],
  validation: {
    type: "string",
    required: true,
    oneOf: ["disc", "helical", "notReceived"],
  },
};

export const DamageReportComponents = {
  bearing,
  shaftTaper,
  shaftOd,
  mechanicalSeal,
  pulley,
  encoder,
  rotaryUnion,
  spacerDistanceSleeve,
  tenon,
  oRings,
  drawbarCollets,
  drawbarSprings,
};
