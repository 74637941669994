import { useFetchData } from "../../api/axios";
import useHelperFunctions from "../../helpers/functions";

function UseGetMandatoryData() {
  const { currentOrder } = useHelperFunctions();

  const { data: taperSpecifications } = useFetchData({
    queryKey: ["taper-specifications"],
    url: `/report/IncomingAlert/${
      currentOrder.IncomingAlert
    }/field?field=${encodeURIComponent(
      "taperSpecifications"
    )}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
  });

  const { data: spindleModel } = useFetchData({
    queryKey: ["spindleModel"],
    url: `/report/IncomingAlert/${
      currentOrder.IncomingAlert
    }/field?field=${encodeURIComponent(
      "spindleModel"
    )}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
  });

  return {
    taperSpecifications: taperSpecifications?.data,
    spindleModel: spindleModel?.data,
    customerName: currentOrder.customerName,
    inwardNumber: currentOrder.inwardNumber,
  };
}

export default UseGetMandatoryData;
