import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useHelperFunctions from "../../helpers/functions";

const ProtectedRoute = ({ children, allowedUsers, notAllowed }) => {
  const { isUser, verifyUser, userRole } = useHelperFunctions();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    verifyUser();
    setIsLoading(false); // Mark loading as complete
  }, [isUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isUser) {
    return <Navigate to="/login" />;
  }

  if (!allowedUsers.includes(userRole)) {
    return <div>You do not have access to this resource</div>;
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;
