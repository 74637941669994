import LoginBlock from "../components/Auth/LoginPaeg";
import Customer from "../components/customer/Customer";
import CustomerTable from "../components/customer/CustomerTable";
import AvailableReport from "../components/downloadReports/AvailableReports";
import FinalInspectionReportDownload from "../components/downloadReports/FinalInspectionReportDownload";
import AllOrders from "../components/home/components/AllOrders";
import CreateOrder from "../components/home/components/createOrder/CreateOrder";
import Home from "../components/home/Home";
import OrderPage from "../components/order/OrderPage";
import OrderStages from "../components/order/OrderStages";
import OrderDashboard from "../components/orderDashboard/OrderDashboard";
import ProtectedRoute from "../components/protectedRoutes/ProtectedRoute";
import FieldForm from "../components/report/FieldForm";
import ReportPage from "../components/report/ReportPage";
import FinalInspection from "../components/seperateForms/FinalInspection/FinalInspection";
import Settings from "../components/settings/Settings";
import SplitScreen from "../layout/SplitScreen";

export const routesConfig = [
  {
    path: "/",
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance"]}>
        <Home />
      </ProtectedRoute>
    ),
    layout: SplitScreen,
    children: [
      {
        path: "create-order",
        element: <CreateOrder />,
      },
    ],
  },
  {
    path: "/all-orders",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance"]}>
        <AllOrders />
      </ProtectedRoute>
    ),
  },
  {
    path: "/order-dashboard",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance", "user"]}>
        <OrderDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/reports",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance", "user"]}>
        <AvailableReport />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin"]}>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user",
    layout: SplitScreen,
    element: <h2>Hey! Welcome to Spintrack</h2>,
  },
  {
    path: "/:orderId",
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance", "user"]}>
        <OrderStages />
      </ProtectedRoute>
    ),
    layout: SplitScreen,
  },

  {
    path: "/:reportName/:reportId",
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance"]}>
        <ReportPage />
      </ProtectedRoute>
    ),
    layout: SplitScreen,
    children: [
      {
        path: ":fieldName",
        element: <FieldForm />,
      },
    ],
  },

  {
    path: "/all-customers",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance"]}>
        <CustomerTable />
      </ProtectedRoute>
    ),
  },
  {
    path: "/add-customer",
    layout: SplitScreen,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance"]}>
        <Customer />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    // layout: SplitScreen,
    element: <LoginBlock />,
  },
  {
    path: "/final-inspection-report-download",
    // layout: SplitScreen,
    // layout: PDFViewer,
    element: (
      <ProtectedRoute allowedUsers={["admin", "sales", "finance", "user"]}>
        <FinalInspectionReportDownload />
      </ProtectedRoute>
    ),
  },
];

// {
//   path: "/FinalInspectionReport/:reportId",
//   element: (
//     <ProtectedRoute>
//       <FinalInspection />
//     </ProtectedRoute>
//   ),
//   layout: SplitScreen,
//   children: [
//     {
//       path: ":fieldName",
//       element: <FieldForm />,
//     },
//   ],
// },
