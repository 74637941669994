import { useFetchData } from "../../api/axios";
import SectionHeader from "../shared/typography/SectionHeader";
import "./report.css";

function ImageCarousal({ currentReport, currentReportId, orderId }) {
  const { data, error } = useFetchData({
    queryKey: [currentReport],
    url: `report/${orderId}/${currentReport}/${currentReportId}`,
  });

  console.log(data?.data, currentReport);

  if (data?.data.length === 0) {
    return <div style={{ width: "0px", height: "0px" }}></div>;
  }

  return (
    <>
      <SectionHeader>{currentReport}</SectionHeader>
      <div
        className="carousal"
        style={{
          border: "1px dashed lightgray",
          margin: "16px 0",
          borderRadius: "8px",
          padding: "16px",
          marginLeft: "24px",
        }}
      >
        {data?.data &&
          data?.data.length !== 0 &&
          [...data.data].reverse().map((u, index) => (
            <div className="imageContainer" key={index}>
              <img src={u} alt={` ${index}`} className="carousalImage" />
            </div>
          ))}
      </div>
    </>
  );
}

export default ImageCarousal;
