import { Button, TextField, Typography } from "@mui/material";
import React from "react";

function LoginForm({ handleLogin, error }) {
  return (
    <form onSubmit={handleLogin} className="mx-auto w-[300px] mt-2">
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
        Login
      </Button>
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
    </form>
  );
}

export default LoginForm;
