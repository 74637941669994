import { useState } from "react";
import { TestingAndBalancingReport } from "../../../config/reports/TestingAndBalancingReport/TestingAndBalancingReport";
import InputField from "../components/InputField";
import "./TestingAndBalancing.css";
import { Divider, Typography } from "@mui/material";
import useHelperFunctions from "../../../helpers/functions";
import { useQueryClient } from "@tanstack/react-query";
import { TableCellHeader1 } from "../components/TableComponents";

function TestingAndBalancing() {
  const [value, setValue] = useState(0);
  const queryClient = useQueryClient(); // can you take this to helpers?

  const { location, currentOrder } = useHelperFunctions();

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const firstSegment = pathSegments[0];
  const secondSegment = pathSegments[1];
  const urlPrefix = `/report/${firstSegment}/${secondSegment}`;

  function onSave() {
    setValue(Math.floor(Math.random() * 1000));
  }
  return (
    <div>
      <TableHeaderContainer />
      <TableContainer>
        {TestingAndBalancingReport.trialRuns.map((row) => {
          return (
            <Table>
              <div>{row.groupHeader}</div>
              <TableRow>
                {row.components.map((c) => {
                  return (
                    <TableCell>
                      <InputField
                        onSave={onSave}
                        prefix={""}
                        suffix={""}
                        value={value}
                        name={c.name}
                        urlPrefix={urlPrefix}
                        orderId={currentOrder.orderId}
                        key={c.name}
                        queryClient={queryClient}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </Table>
          );
        })}
      </TableContainer>

      <TableContainer>
        {TestingAndBalancingReport.temperature.map((row) => {
          return (
            <Table>
              <TableGroupHeader>{row.groupHeader}</TableGroupHeader>
              <TableRow>
                {row.components.map((c) => {
                  return (
                    <TableCell width1={"300px"}>
                      <InputField
                        onSave={onSave}
                        prefix={""}
                        suffix={""}
                        value={value}
                        urlPrefix={urlPrefix}
                        orderId={currentOrder.orderId}
                        key={c.name}
                        name={c.name}
                        queryClient={queryClient}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </Table>
          );
        })}
      </TableContainer>
      <TableContainer>
        {TestingAndBalancingReport.imbalance.map((row) => {
          return (
            <Table>
              <TableGroupHeader>{row.groupHeader}</TableGroupHeader>
              <TableRow>
                {row.components.map((c) => {
                  return (
                    <TableCell width1={"300px"}>
                      <TableCellHeader1>{c.label}</TableCellHeader1>
                      <InputField
                        onSave={onSave}
                        prefix={""}
                        suffix={""}
                        value={value}
                        urlPrefix={urlPrefix}
                        orderId={currentOrder.orderId}
                        key={c.name}
                        name={c.name}
                        queryClient={queryClient}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </Table>
          );
        })}
      </TableContainer>
      <TableContainer>
        {TestingAndBalancingReport.remark.map((row) => {
          return (
            <Table>
              <TableGroupHeader>{row.groupHeader}</TableGroupHeader>
              <TableRow>
                {row.components.map((c) => {
                  return (
                    <TableCell width1={"624px"}>
                      <InputField
                        onSave={onSave}
                        prefix={""}
                        suffix={""}
                        value={value}
                        urlPrefix={urlPrefix}
                        orderId={currentOrder.orderId}
                        key={c.name}
                        name={c.name}
                        queryClient={queryClient}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </Table>
          );
        })}
      </TableContainer>
    </div>
  );
}

export default TestingAndBalancing;

function TableHeaderContainer() {
  return (
    <div className="tableHeaderContainer">
      <div className="tableHeader">RPM</div>
      <div className="tableHeader">Temp (c)</div>
      <div className="tableHeader">Vibration (front)</div>
      <div className="tableHeader">Vibration (rear)</div>
      <div className="tableHeader">Amp</div>
      <div className="tableHeader">Voltage</div>
      <div className="tableHeader">Time</div>
    </div>
  );
}

function TableContainer({ children }) {
  return (
    <div className="tableContainer">
      {children}
      <Divider />
    </div>
  );
}

function Table({ children }) {
  return <div className="table">{children}</div>;
}

function TableGroupHeader({ children }) {
  return <div className="tableGroupHeader">{children}</div>;
}

function TableRow({ children }) {
  return <div className="tableRow">{children}</div>;
}

function TableCell({ children, width1 }) {
  return (
    <div className="tableCell" style={{ width: `${width1}` }}>
      {children}
    </div>
  );
}

function TableCellHeader({ children }) {
  return (
    <Typography variant="body2" color="textSecondary">
      {children}
    </Typography>
  );
}
