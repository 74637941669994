import { TextField, Button, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationPopup from "./NotificationPopup";
import { useMutateData } from "../../../../api/axios";
import useHelperFunctions from "../../../../helpers/functions";
import { useQueryClient } from "@tanstack/react-query";
import DropdownWithSearch from "./DropDownWithSearch";

function InputInward({
  customerData,
  onSelect,
  customer,
  setCustomer,
  selectedOption,
  setSelectedOption,
  setModal,
}) {
  const [inputValue, setInputValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const { setOrderIndex, setCreatedNewOrderTrue } = useHelperFunctions();

  const queryClient = useQueryClient();

  const [popup, setPopup] = useState({
    open: false,
    type: "",
    inwardNumber: "",
  });

  const { mutate, isLoading, error, isSuccess } = useMutateData({
    method: "POST",
    url: "/order",
  });

  const handleClose = () => setPopup({ ...popup, open: false });

  const handleInputChange = (event) => {
    if (!isTouched) {
      setIsTouched(true);
    }
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    let inwardNumber = inputValue;
    let customerId = customer._id;
    let customerName = customer.customerName;
    let data = { inwardNumber, customerName, customerId };
    console.log("submitting");
    mutate(data, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["recentOrders"]);
        console.log("Order created successfully:", data.data);
        setOrderIndex(data.data.data);
        setCreatedNewOrderTrue();
        setPopup({
          open: true,
          type: "success",
          inwardNumber: inwardNumber,
        });
      },
      onError: (error) => {
        console.error("Error creating order:", error);
        setPopup({
          open: true,
          type: "error",
          inwardNumber: inwardNumber,
        });
      },
    });
  };

  function handleClaerSelection() {
    setSelectedOption("");
    setInputValue("");
  }

  console.log(selectedOption, "selectedOption", customer, "customer");

  return (
    <Box
      sx={{
        display: "flex",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <TextField
        label="Enter text"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        error={!inputValue && isTouched} // Show error styling if empty
        helperText={
          !inputValue && isTouched ? "This field cannot be empty" : ""
        }
        fullWidth
      />
      <DropdownWithSearch
        options={customerData}
        onSelect={onSelect}
        setCustomer={setCustomer}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        customer={customer}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 2,
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button onClick={handleClaerSelection} variant="outlined">
          Clear Selection
        </Button>
        <Button
          variant="contained"
          disabled={
            !inputValue ||
            !selectedOption ||
            (customer &&
              (!customer.customerContactEmail ||
                !customer.customerConactPerson ||
                !customer.customerConactPhone))
          } // Disable button if input is empty
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>

      <NotificationPopup
        type={popup.type}
        open={popup.open}
        handleClose={handleClose}
        inwardNumber={inputValue}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        {customer &&
          (!customer.customerContactEmail ||
            !customer.customerConactPerson ||
            !customer.customerConactPhone) && (
            <Box
              sx={{ mt: 4, display: "flex", gap: 2, alignItems: "baseline" }}
            >
              <Typography color="error">
                Customer contact details are missing
              </Typography>
              <Button variant="contained" onClick={() => setModal(true)}>
                Update Details
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export default InputInward;
