export const AllReports = {
  bt50: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
  hsk50: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
  bt40: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
  hsk63: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
  bt30: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
  hsk100: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O On Pulley Dia - ?",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
  ],
};
