import React, { useState } from "react";
import LoginForm from "./LoginForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import sectoImage from "../../assets/staticImages/setcoBanner.webp";
import spintrack from "../../assets/staticImages/spintrack.png";
import setcoLogo from "../../assets/staticImages/setco.png";
import { Box } from "@mui/material";
import "./login.css";
import { axiosInstance } from "../../api/axios";
function LoginBlock({ hideSocial, setHideSocial }) {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      await loginUser(data.get("username"), data.get("password"));
      navigate("/");
    } catch (err) {
      // console.log(err.message);
      console.log(err.message);
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on medium and larger
        height: "100vh", // Full viewport height
      }}
      className="svgBg"
    >
      {/* Image Section */}
      <Box
        sx={{
          flexGrow: 2,
          display: { xs: "none", md: "block" }, // Hide on small screens
          backgroundImage: `url(${sectoImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      {/* Login Form Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <img
              src={setcoLogo}
              alt=""
              style={{ width: "120px", height: "auto" }}
            />
            <img src={spintrack} alt="" width="200px" />
          </div>
          <LoginForm handleLogin={handleLogin} error={error} />
        </div>
      </Box>
    </Box>
  );
}

export default LoginBlock;

const loginUser = async (username, password) => {
  try {
    const response = await axiosInstance.post(`/auth/login`, {
      username,
      password,
    });
    localStorage.setItem("at", response.data.data);
    return response.data.data;
  } catch (error) {
    // console.log(error.response.data.error);
    throw new Error(error.response.data.error);
  }
};
