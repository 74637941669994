import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./navbar.css";
import setco from "../../../assets/staticImages/setco.png";
import useHelperFunctions from "../../../helpers/functions";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Navbar() {
  const {
    navigate,
    isSidebarOpen,
    showSidebar,
    hideSidebar,
    hideOutlet,
    isTabletOrBelow,
    removeUser,
    currentOrder,
    userRole,
  } = useHelperFunctions();

  function handleLogoClick() {
    navigate("/");
    hideOutlet();
  }

  function handleLogout() {
    try {
      localStorage.removeItem("at"); // Remove the token from localStorage
      removeUser();
      console.log("Logout successful: Token removed");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  return (
    <div className="navbar">
      {isTabletOrBelow && (
        <div className="menuIcon">
          {!isSidebarOpen && <MenuIcon onClick={showSidebar} />}
          {isSidebarOpen && <MenuOpenIcon onClick={hideSidebar} />}
        </div>
      )}
      <div className="logoContainer">
        <img
          className="setcoLogo"
          src={setco}
          alt="Setco India"
          onClick={handleLogoClick}
        />
      </div>
      <div className="actionMenu">
        <div className="quickLinksNav">
          {userRole !== "user" && (
            <div>
              <Button>
                <Link to="/all-orders" className="actionItem">
                  Browse orders
                </Link>
              </Button>
              <Button className="actionItem">
                <Link to={`/${currentOrder.orderId}`} className="actionItem">
                  Update reports
                </Link>
              </Button>
            </div>
          )}
        </div>
        <Button onClick={handleLogout} variant="outlined">
          Logout
        </Button>
      </div>
    </div>
  );
}

export default Navbar;
