import useHelperFunctions from "../../helpers/functions";
import ImageCarousal from "./ImageCarousal";
import "./report.css";

const reportNames = [
  "IncomingAlert",
  "CheckSheet",
  "DamageReport",
  "InProcessInspectionReport",
  "OldBearingReport",
  "NewBearingReport",
  "ElectricalTestReport",
  "FinalInspectionReport",
  "TestingAndBalancingReport",
];

function ImageGallery() {
  const { currentOrder } = useHelperFunctions();
  return (
    <>
      <div
        style={{
          maxHeight: "500px",
          maxWidth: "800px",
          marginTop: "24px",
        }}
      >
        {reportNames.map((name) => {
          return (
            <ImageCarousal
              currentReport={name}
              currentReportId={currentOrder[name]}
              orderId={currentOrder.orderId}
            />
          );
        })}
      </div>
      {/* <ImageCarousal
        currentReport={"DamageReport"}
        currentReportId={currentOrder["DamageReport"]}
        orderId={currentOrder.orderId}
      />
      <ImageCarousal
        currentReport={"CheckSheet"}
        currentReportId={currentOrder["CheckSheet"]}
        orderId={currentOrder.orderId}
      /> */}
    </>
  );
}

export default ImageGallery;
