import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import DynamicFormRenderer from "../components/shared/form/dynamic/DynamicForm";
import generateZodSchema from "../components/shared/form/dynamic/validation";
import { useMutateData } from "../api/axios";
import useHelperFunctions from "../helpers/functions";
import { useQueryClient } from "@tanstack/react-query";
import ImageUpload from "../components/shared/form/upload/Upload";

export default function MainForm({ formConfig }) {
  const schema = generateZodSchema([formConfig]);
  const { currentOrder, hideOutlet, location } = useHelperFunctions();
  const queryClient = useQueryClient(); // can you take this to helpers?

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const { mutate, error } = useMutateData({
    method: formConfig.method,
    url: formConfig.url,
  });

  const onSubmit = (data) => {
    let content = {
      field: formConfig.name,
      value: data[formConfig.name],
      orderId: currentOrder["orderId"],
    };
    console.log("Form Data:", content);
    mutate(content, {
      onSuccess: (data) => {
        console.log(data);
        queryClient.invalidateQueries([formConfig.name]);
        hideOutlet();
        // navigate(toNext(currentOrder, stateArray));
      },
      onError: (error) => {
        console.error("Error creating order:", error);
      },
    });
  };
  // let data = Array.isArray(formConfig) ? formConfig : [formConfig];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DynamicFormRenderer
          config={formConfig}
          control={control}
          register={register}
          errors={errors}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>
    </form>
  );
}

// let toNext = (currentOrder, stateArray, staticArray) => {
// 	let nu = navigateUrl(currentOrder, stateArray);
// 	let u = returnUrl(nu);
// 	console.log(u);
// 	return u;
// };

// toNext(currentOrder, stateArray);
